<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">提现记录</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				
				<div class="">
					<div class=" ">
						<a-form layout="inline">
							<a-form-item label="合伙人手机号">
								<a-input v-model="search.mobile" placeholder="请输入合伙人手机号"></a-input>
							</a-form-item>
							
							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="cancelAct" class="ml10">取消</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>
				
				<div class="mt20">
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						 
						<a-radio-button :value="0">
							<div class="flex" style="position: relative;">待审核
								<div class="tag-num positon" v-if="to_do_num>0">{{to_do_num}}</div>
							</div>
						</a-radio-button>
						<a-radio-button :value="1">
						  历史记录
						</a-radio-button>
					</a-radio-group>
				</div>
				
				
				
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="withdraw_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							
							
							<div class="flex alcenter center withdraw-member" slot="partner" slot-scope="partner,record">
								<img v-if="partner.face != null" :src="partner.face"/>
								<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png"/>
								<div class="ml10">{{partner.name}}｜{{partner.mobile}}</div>
							</div>
							
							<div class="flex alcenter center" slot="money" slot-scope="money,record">
								{{money}}元
							</div>
							
							<template slot="action" slot-scope="record">
								<div class="flex center" v-if="record.status==0">
									<a-button type="primary" ghost @click="agreeAct(record)">同意</a-button>
								</div>
								<div class="flex center" v-if="record.status!=0">
									-
								</div>
							</template>
						</a-table>
					</div>
				</div>
			
			</div>
		</div>
	</div>
				
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					mobile:'',
					status:0,
				},
				to_do_num:0,
				columns: [
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '合伙会员',dataIndex: 'partner',align: 'center',scopedSlots: {customRender: 'partner'}},
					{title: '提现金额',dataIndex: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
					{title: '状态',dataIndex: 'status_means',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getPartnerWithdrawList',{
					mobile:this.search.mobile,
					status:this.search.status,
					limit:this.pagination.pageSize,
					page:this.pagination.current
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.to_do_num=res.to_do_num;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			agreeAct(record){
				this.$confirm({
					title:'确定同意这笔提现吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/agreePartnerWithdraw',{
								withdraw_id:record.withdraw_id,
							}).then(res=>{
								this.$message.success('提现成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			changeStatus(){
				this.pagination.current=1;
				this.getLists();
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.withdraw-member img{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
	
	.tag-num{
		background: #FF6600;
		border-radius: 8px;
		padding: 0 5px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		height: 16px;
		line-height: 16px;
	}
</style>
